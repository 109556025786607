import { useEffect, useState } from "react";
import useVesting from "./hooks/useVesting";
import config from "./config.json";
import Utils from "./Utils";

import { useAccount, useNetwork } from "wagmi";

import "./css/Vesting.css";

export default function Vesting() {
    const [loading, setLoading] = useState(false);
    const [claimStatus, setClaimStatus] = useState(null);

    const { address } = useAccount();
    const { chain } = useNetwork();
    const vestingWeb3 = useVesting();

    useEffect(() => {
        setClaimStatus(null);
    }, [address, chain?.id])

    const click = async () => {
        if (loading) return;

        setLoading(true);
        setClaimStatus(null);

        try {
            await vestingWeb3.claim();
            setClaimStatus("Successfully claimed tokens!");
        } catch (e) {
            console.log(e);
            setClaimStatus("Something went wrong.");
        }

        setLoading(false);
    }

    return (
        <div id="vesting">
            <h2> Victoria VR Claim </h2>
            
            {!address || chain?.id != config.chainId ?
                <div> Please connect your wallet for claiming (eth mainnet) </div>
                :
                <div>
                    {vestingWeb3.whitelistedAmount == 0 ?
                        <div> The connected wallet is not whitelisted. </div>
                    :
                        <>
                            <div className="info-box">
                                <div>
                                    Whitelisted amount: 
                                </div>
                                <div>
                                    <span className="highlight-text"> {Utils.fancyNumber(vestingWeb3.whitelistedAmount)} VR </span>
                                </div>
                            </div>
                            <div className="info-box">
                                <div>
                                    You claimed: 
                                </div>
                                <div>
                                    <span className="highlight-text"> {Utils.fancyNumber(vestingWeb3.claimed)} VR </span>
                                </div>
                            </div>
                            <div className="info-box">
                                <div>
                                    You can claim:
                                </div>
                                <div>
                                <span className="highlight-text"> {Utils.fancyNumber(vestingWeb3.claimable)} VR </span>
                                </div>
                            </div>
                            <br></br>
                            <div id="claim-button">
                                <button 
                                    className="button" 
                                    onClick={async (e) => click()} 
                                    disabled={vestingWeb3.claimable == 0}
                                    style={vestingWeb3.claimable == 0 ? {color: "gray", cursor: "not-allowed"} : {}}
                                >
                                    {loading ? 
                                        <div className="loader"></div>
                                    :
                                        "CLAIM"
                                    }
                                </button>
                            </div>
                            {claimStatus && <div id="claim-status">
                                {claimStatus}
                            </div>}
                        </>
                    }
                </div>
            }
        </div>
    );
}