import { ethers } from "ethers";
import { useEffect, useState } from "react";
import abi from "../abi/vesting.json";
import config from "../config.json";

import { useAccount, useNetwork } from "wagmi"

import { getContract, getWalletClient } from '@wagmi/core'
import useEthersSigner from "./useEthersSigner";


export default function useVesting() {
    const [claimable, setClaimable] = useState(null);
    const [claimed, setClaimed] = useState(null);
    const [whitelistedAmount, setWhitelistedAmount] = useState(null);

    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner();

    useEffect(() => {
        getInfo();
    }, [address, chain?.id, signer]);

    const claim = async() => {
        const contract = new ethers.Contract(config.contractAddress, abi, signer);
        const tx = await contract.claim();
        await tx.wait(2);

        await getInfo();
    }

    const getInfo = async() => {
        if (!address || chain?.id != config.chainId || !signer) {
            setClaimable(null);
            setClaimed(null);
            setWhitelistedAmount(null);
            return;
        }

        /*const contract = new ethers.Contract(config.contractAddress, abi, signer);
        const userInfo = await contract.userUnlockInfos(address);
        const currentMonth = await contract.getAvailableMonth();
        
        const whitelistedAmount = Number(ethers.utils.formatUnits(userInfo.amount));
        const claimed = Number(ethers.utils.formatUnits(userInfo.unlockTotal));
        let claimable = whitelistedAmount / 12 * currentMonth - claimed;*/

        const contract = new ethers.Contract(config.contractAddress, abi, signer);
        const userData = await contract.users(address)
        const amount = Number(ethers.utils.formatUnits(userData.amount));
        const claimed = Number(ethers.utils.formatUnits(userData.claimed));

        let claimable = 0
        try {
            claimable = await contract.getClaimable(address)
            claimable = Number(ethers.utils.formatUnits(claimable));
        } catch (e) {}

        if (claimable < 0.01) claimable = 0;
        
        setClaimable(claimable);
        setClaimed(claimed);
        setWhitelistedAmount(amount);
    }

    return {
        claim,
        whitelistedAmount,
        claimed,
        claimable
    }
}