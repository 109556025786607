import React from "react";
import { BrowserRouter as Router, Switch, Route }  from "react-router-dom";
import Header from "./Header.js";
import Vesting from "./Vesting";

import "./css/Global.css";
import "./css/Header.css";

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'

const chains = [mainnet]
const projectId = '1633fbb2b5f1485ae552a4c1189be45d'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

export default function App() {
  return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <Router>
                        <Header/>
                        <Switch>
                            <Route path="/" render={(props) => 
                                <div className="main-tab">
                                    <Vesting></Vesting>
                                </div>
                            }/> 
                        </Switch>
                    </Router>
            </WagmiConfig>

            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
    )
}