class Utils {
    static numberWithCommas(x) {
        if (Number(x) > 1) {
            return Number(x).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return x;
        }
    }

    static fancyNumber(x) {
        if (x !== null && x !== undefined) {
            if (!x) {
                return "0";
            }
            if (Number(x) < 0.0001) {
                return "0"
            } else {
                x = x.toFixed(x >= 1 ? 0 : 4);
                return Utils.numberWithCommas(x);
            }
        } else {
            return "N/A";
        }
    }

    static monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    static fancyHash(x) {
        return x.slice(0,5).concat("...").concat(x.slice(x.length - 3, x.length));
    }
}

export default Utils;